import { GoogleTagManager } from "@next/third-parties/google"
import { defaultTheme } from "@sixty-six-north/ui-system"
import "@sixty-six-north/ui-system/dist/css/indexWithFonts.css"
import { LazyMotion, MotionConfig } from "framer-motion"
import App, { AppContext, AppInitialProps } from "next/app"
import dynamic from "next/dynamic"
import { JSX } from "react"
import { Theme, ThemeUIProvider } from "theme-ui"
import { Themed } from "@theme-ui/mdx"
import { AppConfig } from "../Config"
import "../recaptcha/recaptcha.css"
import "../routing/progress.css"
import { GlobalProps } from "../next/GlobalProps"
import { TagManagerConfig } from "../tagmanager/Config"

const GlobalAppProviders = dynamic(import("../next/GlobalAppProviders"))

const loadMotionFeatures = () =>
  import("../motion/motion-features").then(res => res.default)

if (AppConfig.isProdMode) {
  import("@sentry/react").then(it => {
    import("../sentry/Sentry").then(op => it.init(op.SentryOpts))
  })
}

function capturePaidGACampaignAttributesOnLoad() {
  if (typeof window !== "undefined") {
    const handleWindowLoad = () => {
      const urlParams = new URLSearchParams(window.location.search)
      const gclid = urlParams.get("gclid")
      if (gclid != null) {
        sessionStorage.setItem("gclid", gclid)
      }
    }
    window.addEventListener("load", handleWindowLoad)
  }
}

class MyApp extends App<
  AppInitialProps & { acceptLanguage?: string },
  GlobalProps,
  Record<string, unknown>
> {
  public render(): JSX.Element {
    const { Component, pageProps } = this.props

    capturePaidGACampaignAttributesOnLoad()

    return (
      <ThemeUIProvider theme={defaultTheme as Theme}>
        <GoogleTagManager
          gtmId={TagManagerConfig.tagManagerId}
          preview={TagManagerConfig.tagManagerEnv}
          auth={TagManagerConfig.tagManagerEnvAuth}
        />
        <LazyMotion features={loadMotionFeatures}>
          <MotionConfig reducedMotion="never">
            <Themed.root>
              <GlobalAppProviders
                pageProps={pageProps}
                acceptLanguage={this.props.acceptLanguage || "en"}
              >
                <Component {...pageProps} />
              </GlobalAppProviders>
            </Themed.root>
          </MotionConfig>
        </LazyMotion>
      </ThemeUIProvider>
    )
  }
}

MyApp.getInitialProps = async (
  appContext: AppContext
): Promise<AppInitialProps> => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext)

  // @ts-expect-error acceptLanguage is not a known property of request
  return { ...appProps, acceptLanguage: appContext?.ctx?.req?.acceptLanguage }
}

export default MyApp
